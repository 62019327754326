import React from 'react';
import { Link } from 'react-router-dom';

const PageNotFound = ({ page }) => {
  return (
    <div className="fzf">
      <div className="content">
        <img src="/assets/images/404/404.png" alt="Page not found" />
        <h1>Page Not Found</h1>
        <p>Sorry, we can't find what you're looking for. We all have bad days.</p>
        <Link to="/" className="btn">
          Go to Home Page
        </Link>
      </div>
    </div>
  );
};

export default PageNotFound;
