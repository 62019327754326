import React from 'react';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';

const Footer = () => {
  return (
    <div className="footer">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-12 col-lg-3 col-md-6 mx-auto my-4">
            <div className="box">
              <Link to="/" className="logo">
                <img className="my-2" src="/assets/images/logo/logo.png" alt="logo" />
              </Link>
              <p>
                Registered Address: 85/3, A.V Road, Jaffna, Sri Lanka 40000
              </p>
              <p>
                Office Address: 402/1, Main Street, Jaffna, Sri Lanka 40000
              </p>
              <h4 className="my-2">follow us</h4>
              <div className="social d-flex">
                <div className="icon">
                  <a href="https://www.facebook.com/confluenceventures" target="_blank" rel="noopener noreferrer">
                    <i className="bi bi-facebook"></i>
                  </a>
                </div>
                <div className="icon mx-2">
                  <i className="bi bi-instagram"></i>
                </div>
                <div className="icon mx-2">
                  <a href="https://www.linkedin.com/company/confluenceventures" target="_blank" rel="noopener noreferrer">
                    <i className="bi bi-twitter"></i>
                  </a>
                </div>
                <div className="icon mx-2">
                  <a href="https://www.youtube.com/channel/UCMbDmHlQrKwaHTOPA33G-Og" target="_blank" rel="noopener noreferrer">
                    <i className="bi bi-youtube"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-3 col-md-6 mx-auto d-flex flex-column my-4">
            <h4 className="my-2">site map</h4>
            <ul>
              <li><Link to="/" className="update-link"><i className="bi bi-chevron-right mx-2"></i>home</Link></li>
              <li><Link to="/what-we-do" className="update-link"><i className="bi bi-chevron-right mx-2"></i>What We Do</Link></li>
              <li><Link to="/services" className="update-link"><i className="bi bi-chevron-right mx-2"></i>services</Link></li>
              <li><Link to="/reach-us" className="update-link"><i className="bi bi-chevron-right mx-2"></i>contact</Link></li>
            </ul>
          </div>
          <div className="col-12 col-lg-3 col-md-6 mx-auto d-flex flex-column my-4">
            <h4 className="my-2">follow us</h4>
            <ul>
              <li><a href="https://www.facebook.com/confluenceventures" target="_blank" rel="noopener noreferrer"><i className="bi bi-chevron-right mx-2"></i>facebook</a></li>
              <li><a href="https://www.youtube.com/channel/UCMbDmHlQrKwaHTOPA33G-Og" target="_blank" rel="noopener noreferrer"><i className="bi bi-chevron-right mx-2"></i>youtube</a></li>
              <li><a href="/"><i className="bi bi-chevron-right mx-2"></i>instagram</a></li>
              <li><a href="https://www.linkedin.com/company/confluenceventures" target="_blank" rel="noopener noreferrer"><i className="bi bi-chevron-right mx-2"></i>linkedIn</a></li>
            </ul>
          </div>
          <div className="col-12 col-lg-3 col-md-6 mx-auto d-flex flex-column my-4">
            <h4 className="my-2">our services</h4>
            <ul>
              <li><Link to="/what-we-do/software-development-advanced" className="update-link"><i className="bi bi-chevron-right mx-2"></i>web development</Link></li>
              <li><Link to="/what-we-do/graphic-design-advanced" className="update-link"><i className="bi bi-chevron-right mx-2"></i>graphic design</Link></li>
              <li><Link to="/what-we-do/web-hosting-advanced" className="update-link"><i className="bi bi-chevron-right mx-2"></i>web hosting</Link></li>
              <li><Link to="/what-we-do/digital-marketing-advanced" className="update-link"><i className="bi bi-chevron-right mx-2"></i>digital marketing</Link></li>
            </ul>
          </div>
        </div>
      </div>
      <div className="copyrights">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-6 d-flex justify-content-start">
              <p className="creadits">
                &copy; 2023 Created by: <Link to="/">Confluence Ventures (Pvt) Ltd</Link>
              </p>
            </div>
            <div className="col-12 col-md-6 d-flex justify-content-end">
              <div>
                <Link className="update-link" to="/terms-conditions">Terms of Use</Link>
                | <Link className="update-link" to="/privacy-policy">Privacy Policy</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
