import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import { toast } from 'react-toastify';
import {API_CMS_URL,API_KEY,BlogID,HomePageID, NewsID, VlogID } from './lib/constants';
//import Page from './lib/updateMetaTags';
import NavbarWrapper from './lib/NavbarWrapper';
import generateRouteComponents from './lib/GenerateRouteComponents';
import LoadCMSPage from './lib/LoadCMSPage';
import RotatingLoader from './lib/RotatingLoader'
import PageNotFound from './lib/PageNotFound'
import Footer from './lib/Footer'
import axios from 'axios';
const removeFirstCharacter = (str) => {
  if (str.length > 0) {
    return str.substring(1);
  }
  return str;
};

const getPageName = (url) => {
  const segments = url.split('/');
  let languageCode='';
  if (segments.length > 1) {
    if (segments[0].length===2){
      languageCode=segments[0];
    }
  }
  if (segments.length > 2) {
    const [root, sub] = segments.slice(1); // Extract the first and second segments
    if (root === 'blog' || root === 'news' || root === 'vlog') {
      if (languageCode === '') {
      return `${root}-${sub}`;
      } else {
        return `${languageCode}_${root}-${sub}`;
      }
    }
  }
  if (segments.length > 3) {
    const [root, sub] = segments.slice(2,3); // Extract the first and second segments
    if (root === 'blog' || root === 'news' || root === 'vlog') {
      if (languageCode === '') {
        return `${root}-${sub}`;
      } else {
          return `${languageCode}_${root}-${sub}`;
      }
    }
  }
  url=url.replace(/\//g, '_');
  if (languageCode === '') {
    return removeFirstCharacter(url);
  } else {
    return `${languageCode}_${url}`;
  }
//  return segments.length > 1 ? `/${segments[segments.length - 1]}` : url;
};

function App() {
  const vhref=window.location.pathname;

  const [datamenu, setDatamenu] = useState(null);
  const [language, setLanguage] = useState('');
  
  const [loading, setLoading] = useState(true);

  const [initialPage, setInitialPage] = useState(encodeURIComponent(getPageName(vhref)));
  //console.clear();
  console.log("main"+initialPage);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const apiurl=API_CMS_URL+'initial/'+API_KEY+'/'+initialPage;
        const response = await axios.get(apiurl);
        const jsonData = response.data;
       
        if (!jsonData.success) {
          // Use toast.error() if jsonData indicates failure
          toast.error('Data fetch was unsuccessful: ' + (jsonData.message || 'Unknown Error'));
        }
        if (jsonData.pageData.languageCode){
          setLanguage(jsonData.pageData.languageCode)
        }
        setDatamenu(jsonData); // Update state with fetched data
        setLoading(false);  
      } catch (error) {
        console.error('Failed to fetch data:', error);
        // Use toast.error() to display an error message
        toast.error('Failed to fetch data: ' + error.message);
      }
    };

    fetchData();
  },[]); // Runs only once on component mount

  if (loading) {
    return <RotatingLoader />;
  }

  return (
    <Router>
      <ToastContainer />      
      <NavbarWrapper menuDisplay={datamenu.menuDisplay} 
      languages={datamenu.languages} language={language}
      pageName={initialPage} />
      <Routes>
        {generateRouteComponents(datamenu.menuDisplay,datamenu)}
        <Route key="-1" path="news/:slug" element={<LoadCMSPage pageId={NewsID} pageName="news" initialPageData={datamenu}  />} />
        <Route key="-2" path="blog/:slug" element={<LoadCMSPage pageId={BlogID}  pageName="blog" initialPageData={datamenu}  />} />
        <Route key="-3" path="vlog/:slug" element={<LoadCMSPage pageId={VlogID}  pageName="vlog" initialPageData={datamenu}  />} />
        <Route key="-10"  path="*" element={<PageNotFound page="test" />} /> {/* Catch-all route */} 
      </Routes>
      <Footer/>
    </Router>
  );
}

export default App;
