import React from 'react';
import { Route, Outlet } from 'react-router-dom';
import LoadCMSPage from './LoadCMSPage';

const generateRouteComponents = (menuData, fullData) => {
  return menuData.map((item) => {
    const hasSubMenu = item.subMenu && item.subMenu.length > 0;
    const initialPageData = (fullData.pageData.pageId === item.id) ? fullData : null;

    return (
      <Route
        key={item.id}
        path={item.pageName}
        element={hasSubMenu ? <Outlet /> : <LoadCMSPage pageId={item.id} pageName={item.pageName} initialPageData={initialPageData} />}
      >
        {hasSubMenu && generateRouteComponents(item.subMenu, fullData)}
        hasSubMenu && <Route
     key={item.id}
     path={item.pageName}
     element={<LoadCMSPage pageId={item.id} pageName={item.pageName} initialPageData={initialPageData} />}
   />      
      </Route>
      
    );
  });
};

export default generateRouteComponents;
