import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import DarkModeToggle from './DarkModeToggle';
import LanguageSelector from './Languages';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import '../css/misc.css'; 

function Navbar({ menuDisplay, language,languages, onLanguageChange, initialLanguageCode}) {
  const [isNavCollapsed, setIsNavCollapsed] = useState(true);
  const [openDropdown, setOpenDropdown] = useState(null);
  const [isDarkMode, setIsDarkMode] = useState(false);

  useEffect(() => {
    const darkModeClass = 'dark-mode';
    const navbarClass = 'navbar-dark-mode';
    if (document.body.classList.contains(darkModeClass)) {
      setIsDarkMode(true);
    } else {
      setIsDarkMode(false);
    }
    const observer = new MutationObserver(() => {
      if (document.body.classList.contains(darkModeClass)) {
        setIsDarkMode(true);
      } else {
        setIsDarkMode(false);
      }
    });
    observer.observe(document.body, { attributes: true, attributeFilter: ['class'] });
    return () => observer.disconnect();
  }, []);

  const handleNavCollapse = () => setIsNavCollapsed(!isNavCollapsed);
  const handleLinkClick = () => {
    setIsNavCollapsed(true);
    setOpenDropdown(null); // Close any open dropdowns
  };

  const handleDropdownClick = (id) => {
    if (openDropdown === id) {
      setOpenDropdown(null);
    } else {
      setOpenDropdown(id);
    }
  };

//console.log('language:'+language+':');
//console.log(menuDisplay);
  // Filter menuDisplay to include only items with hasMenu set to true
  const filteredMenuDisplay = menuDisplay.filter(menu => menu.hasMenu && menu.languageCode === language);
  // Determine the URL based on the language
  const homeUrl = language === '' ? '/' : `/${language}`;
  return (
    <nav className={`navbar navbar-expand-lg ${isDarkMode ? 'navbar-dark-mode' : 'navbar-light bg-light'}`}>
      <NavLink className="navbar-brand" to={homeUrl} key='a'>
        <img className='logo' alt='logo' src='/assets/images/logo/logo.png' />
      </NavLink>
      <button
        className="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarNavDropdown"
        aria-controls="navbarNavDropdown"
        aria-expanded={!isNavCollapsed ? true : false}
        aria-label="Toggle navigation"
        onClick={handleNavCollapse}
      >
        <span className="navbar-toggler-icon"></span>
      </button>
      <div className={`${isNavCollapsed ? 'collapse' : ''} navbar-collapse`} id="navbarNavDropdown">
        <ul className="navbar-nav">
          {filteredMenuDisplay.map((menu) => (
            <li
              key={menu.id}
              className={`nav-item ${menu.subMenu ? 'dropdown' : ''} ${openDropdown === menu.id ? 'show' : ''}`}
              onClick={menu.subMenu ? () => handleDropdownClick(menu.id) : handleLinkClick}
            >  

              <NavLink
                className={`nav-link ${menu.subMenu ? 'dropdown-toggle' : ''}`}
                to={menu.pageName}
                id={`navbarDropdownMenuLink-${menu.id}`}
                role="button"
                data-bs-toggle={menu.subMenu ? 'dropdown' : undefined}
                aria-haspopup="true"
                aria-expanded={openDropdown === menu.id ? 'true' : 'false'}
                onClick={menu.subMenu ? undefined : handleLinkClick}
              >
                {menu.menuName}
              </NavLink>
              {menu.subMenu && (
                <ul className={`dropdown-menu ${openDropdown === menu.id ? 'show' : ''}`} aria-labelledby={`navbarDropdownMenuLink-${menu.id}`}>
                  {menu.subMenu.filter(subMenu => subMenu.hasLink).map((subMenu) => (
                    <li key={subMenu.id}>
                      <NavLink className="dropdown-item" to={subMenu.pageName} onClick={handleLinkClick}>
                        {subMenu.menuName}
                      </NavLink>
                    </li>
                  ))}
                </ul>
              )}
            </li>
          ))}
        </ul>
        
        {languages && (
          <LanguageSelector
            languages={languages}
            onLanguageChange={onLanguageChange}
            initialLanguageCode={initialLanguageCode}
          />

        )}
                <DarkModeToggle />

      </div>
    </nav>
  );
}

export default Navbar;
