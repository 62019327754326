import React, { useState } from 'react';
import 'bootstrap-icons/font/bootstrap-icons.css';
import '../css/DarkModeToggle.css'; // Import the CSS file for styling

const DarkModeToggle = () => {
  const [isDarkMode, setIsDarkMode] = useState(false);

  const toggleDarkMode = () => {
    setIsDarkMode(!isDarkMode);
    document.body.classList.toggle('dark-mode', !isDarkMode);
  };

  return (
    <div className="d-flex ms-auto">
      <button id="mode-toggle" className="btn-light-mode switch-button" onClick={toggleDarkMode}>
        <i id="mode-icon" className={`bi ${isDarkMode ? 'bi-sun-fill' : 'bi-moon-fill'}`}></i>
      </button>
    </div>
  );
};

export default DarkModeToggle;
