// RotatingLoader.js

import React from 'react';
import '../css/RotatingLoader.css'; // Import the CSS file for styling
import logo from '../images/logo.png'
const RotatingLoader = () => {
  return (
    <div className="loader-container">
      <img src={logo} alt="Loading........." className="flip-image" />
    </div>
  );
};

export default RotatingLoader;
